export default class globalContext {
    static threadId = null;

    static newThread = false;

    static enableNewMathConfig = false;

    static imageCopilotMode = false;

    static imageContextData = {};

    static tenantId = null;

    static tenantKey = null;

    static env = null;
    // add more static variables as needed
}
