import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Box, Link, Typography, withStyles
} from '@material-ui/core';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';
import messages from '../../defaultMessages';

const styles = () => ({
  contentWrapper: {
    marginTop: 16
  },
  content: {
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16
  },
  link: {
    color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'anchorLinkColor')),
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 700,
    cursor: 'pointer',
    textDecoration: 'underline',
    lineHeight: '24px',
    letterSpacing: '0.24px'
  }
});

const CustomReferenceLinkMessage = ({
  classes, pageId, title, intl, sourceLinkUrl, onPageNavigation
}) => {
  const { formatMessage } = intl;
  const handleOpenEtextBook = () => {
    if (pageId) {
      if (sourceLinkUrl) {
        const formattedPageUrl = sourceLinkUrl.replace('contextId', pageId);
        window.open(formattedPageUrl, '_blank');
      } else {
        onPageNavigation(pageId);
      }
    }
  };
  return (
    <>
      <Box className={classes.contentWrapper}>
        <Typography className={classes.content} variant="body2" component="p">
          {formatMessage(messages.referenceLinkText)}
          {' '}
          <strong>{title}</strong>
        </Typography>
        <Box className={classes.linkWrapper}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link className={classes.link} onClick={handleOpenEtextBook}>{formatMessage(messages.openInETextbook)}</Link>
        </Box>
      </Box>
    </>
  );
};

CustomReferenceLinkMessage.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sourceLinkUrl: PropTypes.string.isRequired,
  onPageNavigation: PropTypes.func.isRequired
};

export default withStyles(styles)(injectIntl(CustomReferenceLinkMessage));
