import { useCallback, useMemo } from 'react';
import { ACTION_ICONS } from '../../Common/constants';

const useMoreActions = (customConfig) => {
  const getActionIconVisibility = useCallback(
    (action) => (customConfig?.showActionIcons?.[action] ?? true),
    [customConfig]
  );

  const showCopy = useMemo(() => getActionIconVisibility(ACTION_ICONS.COPY), [getActionIconVisibility]);
  const showSaveAsNote = useMemo(() => getActionIconVisibility(ACTION_ICONS.SAVE_AS_NOTE), [getActionIconVisibility]);
  const showSaveAsFlashcard = useMemo(() => getActionIconVisibility(ACTION_ICONS.SAVE_AS_FLASHCARD), [getActionIconVisibility]);

  return { showCopy, showSaveAsNote, showSaveAsFlashcard };
};

export default useMoreActions;
