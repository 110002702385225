/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * Channel Services
 * @author Sarathkumar Venkatachalam
 */

import axios from 'axios';
import globalContext from '../../Context/globalContext';
import * as constants from '../../Common/constants';

export default class ChannelServices {
  constructor() {
    const headers = {
      'Content-Type': 'application/json',
      'x-berlin-tenant-id': globalContext.tenantId,
      'x-berlin-tenant-key': globalContext.tenantKey
    };
    this.channelClient = axios.create({
      baseURL: constants.CHANNEL_BASE_URL[globalContext.env] || constants.CHANNEL_BASE_URL.qa,
      timeout: 180000,
      headers
    });
  }

  fetchVideo(data, callback) {
    const { channelId, userTopic, token } = data;
    const headerParams = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    return new Promise((resolve, reject) => {
      this.channelClient.get(`search/etext/${channelId}?q=${userTopic}`, headerParams).then(
        (response) => {
          resolve(response.data);
          callback(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
