/* eslint-disable no-unused-vars */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file QuizAnswerComponent.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React, {
  useState, useRef, useEffect, memo,
  useMemo
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Link,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Utilities } from '@aquila/core';
import * as constants from '../../Common/constants';
import PositiveFeedback from '../../Components/FeedbackComponent/PositiveFeedback';
import NegativeFeedback from '../../Components/FeedbackComponent/NegativeFeedback';
import MoreMenuIcon from '../../Common/icons/MoreMenuIcon';
import LikeIcon from '../../Common/icons/LikeIcon';
import LikeIconFilled from '../../Common/icons/LikeIconFilled';
import DisLikeIcon from '../../Common/icons/DisLikeIcon';
import DisLikeIconFilled from '../../Common/icons/DisLikeIconFilled';
import messages from '../../defaultMessages';
import FlashCardIcon from '../../Common/icons/FlashCardIcon';
import CopyIcon from '../../Common/icons/CopyIcon';
// import * as constants from '../../Common/constants';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ThemeConstants from '../../Common/ThemeConstants';
import '../../main.scss';
import Utils from '../../Common/Utils';
import { clickMoreActionsGAEvent, clickThumbsGAEvent } from '../../analytics/analyticsEvents';
import globalContext from '../../Context/globalContext';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';
import useMoreActions from '../../Components/Hooks/useMoreActions';

const styles = () => ({
  QuizAnswerComponent_container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  moreIconStyle: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15
  },
  feedbackButton: {
    padding: 8
  },
  moreActionList: {
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    margin: '0 auto',
    listStyle: 'none',
    background: (props) => (getThemeValue(props, 'menuList', 'bgColor', false, 'bgColor')),
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    width: 240
  },
  iconRootStyle: {
    minWidth: 20,
    marginRight: 7
  },
  textRootStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'menuList', 'textColor', false, 'textColor'))
  },
  poperStyle: {
    zIndex: '99999 !important'
  },
  iconsTooltip: {
    background: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')),
    color: (props) => (getThemeValue(props, 'toolTip', 'textColor', false, 'toolTipTextColor')),
    border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`),
    padding: 8,
    width: 'fit-content',
    borderRadius: '4px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  quizAnswerDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    marginBottom: 0,
    display: 'block',
    color: (props) => (getThemeValue(props, 'secondarySendBtn', 'textColor', false, 'textColor'))
  },
  correctAnswerWrapper: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    marginTop: 16,
    marginBottom: 0,
    display: 'block',
    color: (props) => (getThemeValue(props, 'secondarySendBtn', 'textColor', false, 'textColor'))
  },
  tooltipArrow: {
    color: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor'))
  },
  moreActionListContainer: {
    padding: 0
  },
  listItemRootStyle: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0
  },
  listAlignment: {
    marginBottom: 0
  },
  quizContainer__actions__buttons__next: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',
    width: 106,
    height: 40,
    background: (props) => (getThemeValue(props, 'secondarySendBtn', 'bgColor', false, 'bgColor')),
    border: '1px solid #BCC1CB',
    borderRadius: 18,
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 700,
    color: (props) => (getThemeValue(props, 'secondarySendBtn', 'textColor', false, 'textColor')),
    fontFamily: 'TT Commons'
  },
  doneAction: {
    textDecoration: 'underline',
    // width: '100%',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'anchorLinkColor')),
    cursor: 'pointer',
    textAlign: 'right'
  },
  doneContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginRight: 10
  },
  copyWrapperHidden: {
    display: 'none'
  }
});

const QuizAnswerComponent = memo(({
  classes,
  intl,
  bookId,
  answerExplanation,
  isCorrect,
  correctAnswer,
  handleGAEvents,
  handleSubmitFeedback,
  quizData,
  onFlashCardClick,
  pageIds,
  feedbackIconFocusId,
  onCopy,
  theme,
  masterTheme,
  handleNextQuizQuestion,
  handleQuizStop,
  disableOptions,
  selectedAnswerIndexId,
  chapterData,
  customConfig,
  customThemeStyles
}) => {
  const text = correctAnswer;
  const { formatMessage } = intl;
  const [showMoreActionOptions, setMoreActionOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const explainRef = useRef(null);
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isClickAway, setIsClickAway] = useState(false);
  console.log('customThemeStyles', customThemeStyles);

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  useEffect(() => {
    explainRef.current.focus();
  }, []);

  const onMoreActionClick = (event, isMoreActionDialogueOpen = false) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMoreActionOptions(!showMoreActionOptions);
    if (typeof isMoreActionDialogueOpen === 'boolean' && isMoreActionDialogueOpen) clickMoreActionsGAEvent(handleGAEvents, constants.GPT_EVENTS.PRACTICE, bookId);
  };

  const handleCopy = () => {
    onMoreActionClick();
    if (`${quizData.requestId}_${quizData.id}`) {
      const copyTextId = document.getElementById(`${quizData.requestId}_${quizData.id}`);
      copyTextId.innerHTML = Utils.quizCopyTemplate(constants.MULTIPLE_CHOICE, quizData.question_stem, quizData.options, '', answerExplanation, selectedAnswerIndexId);
      const copyText = copyTextId.innerHTML;
      Utils.exportCopyText(copyText);
      onCopy();
      copyTextId.innerHTML = '';
    }
    const additionalFields = {
      event_label: 'Copy',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.PRACTICE
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleFlashCard = () => {
    onMoreActionClick();
    onFlashCardClick(quizData, pageIds, chapterData);
    const additionalFields = {
      event_label: 'Save as Flashcard',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.PRACTICE
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, constants.GPT_EVENTS.QUESTION, constants.GPT_EVENTS.PRACTICE_TYPE);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, constants.GPT_EVENTS.QUESTION, constants.GPT_EVENTS.PRACTICE_TYPE);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    let payloadInfo = {
      type: 'summary',
      requestId: quizData.requestId,
      responseId: quizData.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: ''
    };
    setIsClickAway(false);
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
  };

  const handleSubmitPositiveFeedback = (value) => {
    const payloadInfo = {
      type: 'quiz',
      requestId: quizData.requestId,
      responseId: quizData.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: quizData
    };
    setPostiveFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    const payloadInfo = {
      type: 'quiz',
      requestId: quizData.requestId,
      responseId: quizData.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      questionId: quizData.questionId,
      data: quizData
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  const removeAnswerIndexes = (correctAnswerValue) => {
    const removeAnswerPrefix = correctAnswer.slice(8);
    const str = removeAnswerPrefix.toLowerCase();
    for (let i = 0; i < str.length; i += 1) {
      if ((str[i] >= 'a' && str[i] <= 'z')
        && ((str[i + 1] >= 'a' && str[i + 1] <= 'z') || (str[i + 1] === ' ') || (str[i + 1] >= '0' && str[i + 1] <= '9'))) {
        return correctAnswerValue.slice(8 + i);
      }
    }
    return str;
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };

  const { showCopy, showSaveAsFlashcard } = useMoreActions(customConfig);
  const showMoreActionsIcon = useMemo(() => showCopy || showSaveAsFlashcard, []);

  return (
    <Box className={classes.QuizAnswerComponent_container}>
      <>
        {/* isCorrect ? <Typography variant="paragraph" className={classes.quizAnswerDescription}>{`That's correct! ${answerExplanation || ''}`}</Typography>
          : <React.Fragment><Typography variant="paragraph" className={classes.quizAnswerDescription}>{`Sorry, that's incorrect. ${answerExplanation || ''} `}</Typography></React.Fragment>
  */}
        <Typography variant="paragraph" className={classes.quizAnswerDescription} ref={explainRef}>
          {Utilities.textFromContent(answerExplanation, globalContext.enableNewMathConfig) || ''}
        </Typography>
        <div className={classes.copyWrapperHidden} id={`${quizData.requestId}_${quizData.id}`} />
      </>
      <Box className={classes.actionContainer}>
        <div>
          {
            !isConfirmed && !disableOptions && (
            <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Next" title="Next" placement="top" arrow>
              <Button
                onClick={() => {
                  setIsConfirmed(true);
                  handleNextQuizQuestion(null, null, null, null, null, false, pageIds, false, '', chapterData);
                }}
                className={classes.quizContainer__actions__buttons__next}
              >
                Next
              </Button>
            </Tooltip>
            )
          }
        </div>
        <div>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as positive" title="Report as positive" placement="top" arrow>
            <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
              {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <LikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as negative" title="Report as negative" placement="top" arrow>
            <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
              {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
            </IconButton>
          </Tooltip>
          {showMoreActionsIcon && (
          <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label={constants.MORE_ACTIONS} aria-haspopup title={formatMessage(messages.moreActions)} placement="top" arrow>
            <IconButton className={classes.feedbackButton} onClick={(event) => onMoreActionClick(event, true)} aria-label={constants.MORE_ACTIONS}>
              <MoreMenuIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
            </IconButton>
          </Tooltip>
          )}
        </div>
      </Box>
      {
        !isConfirmed && !disableOptions && (
          <div className={classes.doneContainer}>
            <Link
              href={false}
              component="button"
              variant="body1"
              className={classes.doneAction}
              onClick={() => {
                setIsConfirmed(true);
                handleQuizStop();
              }}
            >
              {formatMessage(messages.no)}
            </Link>
          </div>
        )
      }
      {
        showMoreActionOptions && (
          <Menu
            id="moreActionOptions"
            open={showMoreActionOptions}
            anchorEl={anchorEl}
            classes={{ paper: classes.moreActionList, list: classes.moreActionListContainer }}
            className={classes.poperStyle}
            keepMounted
            onClose={onMoreActionClick}
          >
            {showSaveAsFlashcard && (
            <MenuItem
              button
              onClick={handleFlashCard}
              classes={{
                root: classes.listItemRootStyle
              }}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <FlashCardIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.saveFlashcard)}
              </ListItemText>
            </MenuItem>
            )}
            {showCopy && (
            <MenuItem
              button
              classes={{
                root: `${classes.listItemRootStyle} ${classes.listAlignment}`
              }}
              onClick={handleCopy}
            >
              <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                <CopyIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.textRootStyle }}>
                {formatMessage(messages.copy)}
              </ListItemText>
            </MenuItem>
            )}
          </Menu>
        )
      }
      {
        showPositiveFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
          >
            <PositiveFeedback
              handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
              handleClose={handleClose}
              theme={theme}
              masterTheme={masterTheme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
      {
        showNegativeFeedbackPopUp && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
          >
            <NegativeFeedback
              handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
              handleClose={handleClose}
              theme={theme}
              masterTheme={masterTheme}
              isClickAway={isClickAway}
            />
          </ClickAwayListener>
        )
      }
    </Box>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

QuizAnswerComponent.defaultProps = {
  theme: 'default',
  masterTheme: 'plus',
  pageIds: [],
  feedbackIconFocusId: {},
  disableOptions: false,
  selectedAnswerIndexId: 0,
  customThemeStyles: {},
  customConfig: {},
  chapterData: {}
};

QuizAnswerComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  answerExplanation: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  correctAnswer: PropTypes.string.isRequired,
  bookId: PropTypes.string.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  feedbackIconFocusId: PropTypes.object,
  quizData: PropTypes.object.isRequired,
  onFlashCardClick: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  pageIds: PropTypes.array,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  handleNextQuizQuestion: PropTypes.func.isRequired,
  handleQuizStop: PropTypes.func.isRequired,
  disableOptions: PropTypes.bool,
  selectedAnswerIndexId: PropTypes.number,
  customThemeStyles: PropTypes.object,
  customConfig: PropTypes.object,
  chapterData: PropTypes.object
};

export default memo(withStyles(styles)(injectIntl(QuizAnswerComponent)));
